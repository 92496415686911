import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import ProductHome from "../components/ProductComponents/General/ProductHome";
import Splash from "../components/ProductComponents/General/Splash";
import Wrapper from "../components/Wrapper";
import { pageTransition } from "../utils/transition";
import { organizationStructuredData } from "../structured-data";
import { useParams } from "react-router-dom";
import { getDeviceType } from "../utils/deviceType";

const Products = () => {
  const { slug } = useParams();

  const [isLoad, setIsLoad] = useState(true);
  const [isLoadContent, setIsLoadContent] = useState(true);
  const deviceType = getDeviceType();

  useEffect(() => {
    console.log(slug, "sluggers");
    if (slug) {
      if (deviceType === "Android") {
        //play.google.com/store/apps/details?id=com.germiny_user
        https: window.location.href = `https://play.google.com/store/apps/details?id=${
          slug === ":practitioner" ? "com.germinypract" : "com.germiny_user"
        }`;
      } else if (deviceType === "iOS") {
        window.location.href = `https://apps.apple.com/app/${
          slug === ":practitioner" ? "germiny-practitioner/id6451323328" : ""
        }`;
      }
    }
    const timer2 = setTimeout(() => {
      setIsLoad(false);
    }, 3500);
    const timer3 = setTimeout(() => {
      setIsLoadContent(false);
    }, 3000);
    return () => {
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);

  return (
    <>
      {isLoad ? (
        <Splash {...{ isLoad, isLoadContent }} />
      ) : (
        <>
          <Helmet>
            <title>Germiny&reg; - Check out our amazing products</title>
            <meta property="og:title" content="Germiny Products Page" />
            <meta property="og:site_name" content="Germiny" />
            <meta property="og:url" content="https://germiny.org" />
            <meta property="og:type" content="website" />
            <meta
              property="og:description"
              content="Germiny Products page &mdash; Primary healthcare is one of the most important aspects of the healthcare system. Accessibility and affordability of the right healthcare professional plays a huge role in getting this care. The Germiny Practitioner is concerned with maintaining and restoring health through the diagnosis, prognosis and treatment of diseases, injuries and other physical and mental impairments in the comfort of your home, office or any where you are."
            />

            <script type="application/ld+json">
              {organizationStructuredData({
                headline: "Products Page",
                authorName: "Arifayan Idowu",
                description:
                  "We are a technology driven company focused on the development of mobile softwares aimed at bringing convenient world class services to individuals, families, and companies, wherever they are",
              })}
            </script>
          </Helmet>
          <Wrapper>
            <motion.div
              layout
              variants={pageTransition}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <ProductHome />
            </motion.div>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default Products;
